import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const BlogLink = ({ url, text }) => {
  return (
    <a href={url} target="_blank" className="text-blue-500">
      {text}
    </a>
  );
};

export default BlogLink;
