import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const elements = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
};
const Heading = ({ type, children, ...props }) => {
  const className = "font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]";
  return React.createElement(elements[type] || elements.h1, { className, ...props }, children);
};

export default Heading;
