import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const CommonParagraph = ({ children }) => {
  return (
    <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg [&_ul]:list-disc [&_ul]:list-inside">
      {children}
    </p>
  );
};

export default CommonParagraph;
